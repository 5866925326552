<template>
    <div
        class="px-4 w-full dF fC f1 pb-4 hide-scrollbar"
        style="overflow-y: scroll"
		v-if="user"
    >
        <div
            class="mb-3 dF aC"
            style="font-size: 24px; gap: 20px; justify-content: space-between"
        >
            <div>
                <a-icon type="arrow-left" @click="$router.go(-1)" />
                <strong class="ml-3">{{
                    user.firstName + " " + user.lastName
                }}</strong>
            </div>

            <div>
                <a-button @click="resetPasswordModal = true"
                    >RESET PASSWORD</a-button
                >
            </div>
        </div>
        <bh-loading :show="loading" />
        <div class="profile mt-2">
            <div :class="$mq == 'md' ? '' : 'dF'" style="gap: 20px">
                <div
                    :class="$mq == 'md' ? 'mt-3' : ''"
                    :style="$mq == 'md' ? 'width:100%' : 'width:75%'"
                >
                    <a-card>
                        <h6 class="mb-4 text-dark">
                            <strong>User Details</strong>
                        </h6>
                        <hr style="margin-left: -24px; margin-right: -24px" />

                        <a-form-model
                            ref="ruleForm"
                            :model="userData"
                            :rules="rules"
                        >
                            <a-row :gutter="16">
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        ref="isPartner"
                                        label="User Type"
                                        prop="isPartner"
                                    >
                                        <a-input
                                            :value="userType"
                                            placeholder="User Type"
                                            size="large"
											disabled
                                            required
                                        >
                                        </a-input>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        ref="userID"
                                        label="User ID"
                                        prop="userID"
                                    >
                                        <a-input
                                            v-model="user.readableId"
                                            placeholder="User ID"
                                            size="large"
                                            disabled
                                        >
                                        </a-input>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="16">
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        ref="firstName"
                                        label="First Name"
                                        prop="firstName"
                                    >
                                        <a-input
                                            v-model="userData.firstName"
                                            placeholder="First Name"
                                            size="large"
                                            required
                                        >
                                        </a-input>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        ref="lastName"
                                        label="Last Name"
                                        prop="lastName"
                                    >
                                        <a-input
                                            v-model="userData.lastName"
                                            placeholder="Last Name"
                                            size="large"
                                            required
                                        >
                                        </a-input>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="16">
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        prop="phone"
                                        label="Phone Number"
                                        required
                                    >
                                        <a-input
                                            v-model="userData.phone"
                                            placeholder="Phone Number"
                                            size="large"
                                            type="number"
                                        >
                                        </a-input>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        prop="userRole"
                                        label="Your Role"
                                        required
                                    >
                                        <a-input
                                            class="input-border-style"
                                            size="large"
                                            v-model="userData.userRole"
                                        />
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="16">
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        prop="company"
                                        label="Company Name"
                                        required
                                    >
                                        <a-input
                                            v-model="userData.company"
                                            placeholder="Company"
                                            size="large"
                                        >
                                        </a-input>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        prop="industry"
                                        label="Business Type"
                                        required
                                    >
                                        <a-select
                                            v-model="userData.industry"
                                            size="large"
                                        >
                                            <a-select-option value="Builder">
                                                Builder
                                            </a-select-option>
                                            <a-select-option
                                                value="Real Estate Broker"
                                            >
                                                Real Estate Broker
                                            </a-select-option>
                                            <a-select-option
                                                value="Advertising Agency"
                                            >
                                                Advertising Agency
                                            </a-select-option>
                                            <a-select-option value="Freelancer">
                                                Freelancer
                                            </a-select-option>
                                            <a-select-option value="Other">
                                                Other
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="16">
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        prop="address"
                                        label="Address"
                                        required
                                    >
                                        <a-input
                                            class="input-border-style"
                                            size="large"
                                            v-model="userData.address"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 24 : 12">
                                    <a-form-model-item
                                        prop="country"
                                        label="Country"
                                        required
                                    >
                                        <a-select
                                            show-search
                                            size="large"
                                            class="dropdown-style"
                                            v-model="userData.country"
                                            @change="
                                                userData.region = null;
                                                userData.postal = null;
                                            "
                                        >
                                            <a-select-option
                                                v-for="country in countries"
                                                :key="country.value"
                                                :value="country.name"
                                            >
                                                {{ country.name }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 8">
                                    <a-form-model-item
                                        prop="city"
                                        label="City"
                                        required
                                    >
                                        <a-input
                                            class="input-border-style"
                                            size="large"
                                            v-model="userData.city"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 24 : 8">
                                    <a-form-model-item
                                        prop="region"
                                        label="State/Province"
                                        required
                                    >
                                        <a-input
                                            class="input-border-style"
                                            size="large"
                                            v-model="userData.region"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 24 : 8">
                                    <a-form-model-item
                                        prop="postal"
                                        label="Zip/Postal Code"
                                        required
                                    >
                                        <a-input
                                            class="input-border-style"
                                            size="large"
                                            v-model="userData.postal"
                                        />
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </a-form-model>

                        <div class="dF jE w-full" style="gap: 20px">
                            <a-button @click="$router.go(-1)">CANCEL</a-button>
                            <a-button type="primary" @click="updateUser"
                                >SAVE</a-button
                            >
                        </div>
                    </a-card>
                </div>
                <div :style="$mq == 'md' ? 'width:100%' : 'width:25%'">
                    <a-card class="profile-card">
                        <a-avatar
                            class="mb-3"
                            :size="100"
                            style="font-size: 50px"
                            >{{
                                getInitial(user.firstName, user.lastName)
                            }}</a-avatar
                        >
                        <h5>
                            {{ user.firstName + " " + user.lastName }}
                        </h5>
                        <h6>
                            {{ user.userRole }}
                        </h6>
                        <h6 class="text-primary">
                            {{ user.phone }}
                        </h6>
                        <h6 style="color: #9ea0a5">
                            {{ user.email }}
                        </h6>
                    </a-card>
                </div>
            </div>
        </div>
        <a-modal
            v-model="resetPasswordModal"
            title="Reset Password"
            ok-text="SEND"
            @ok="resetPassword"
            :centered="true"
        >
            <p>
                Are you sure you want to send reset password email to
                <strong> {{ user.email }} </strong>
            </p>
        </a-modal>
    </div>
</template>

<script>
import { getInitial } from "bh-mod";
import countryData from "@/staticData/countryData";
import postalCodes from "postal-codes-js";
import bhLoading from "bh-mod/components/common/Loading";

export default {
    components: {
        bhLoading,
    },
    data: () => {
        return {
            loading: false,
            resetPasswordModal: false,
            countries: countryData.countryList,
            userData: {
                isPartner: false,
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                company: "",
                industry: "",
                userRole: "",
                address: "",
                country: "",
                region: "",
                city: "",
                postal: "",
            },
        };
    },
    computed: {
        user() {
            return this.$store.state.selectedUser;
        },

        rules() {
            return {
                postal: [
                    { validator: this.validatePostalCodes, trigger: "change" },
                ],
                firstName: [
                    {
                        required: true,
                        message: "Please enter your first name",
                        trigger: "change",
                    },
                ],
                lastName: [
                    {
                        required: true,
                        message: "Please enter your last name",
                        trigger: "change",
                    },
                ],
                userRole: [
                    {
                        required: true,
                        message: "Please enter your role",
                        trigger: "change",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "Please enter your business number",
                        trigger: "change",
                    },
                ],
                company: [
                    {
                        required: true,
                        message: "Please enter your company name",
                        trigger: "change",
                    },
                ],
                industry: [
                    {
                        required: true,
                        message: "Please select your business type",
                        trigger: "change",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "Please enter your address",
                        trigger: "change",
                    },
                ],
                country: [
                    {
                        required: true,
                        message: "Please select your country",
                        trigger: "change",
                    },
                ],
                region: [
                    {
                        required: true,
                        message: "Please enter your region",
                        trigger: "change",
                    },
                ],
				city: [
                    {
                        required: true,
                        message: "Please enter your city",
                        trigger: "change",
                    },
                ],
            };
        },

		userType() {
			const types = [];
			if (this.user.partners && this.user.partners.length) {
				types.push("Partner");
			}
			if (this.user.ownInstances && this.user.ownInstances.length) {
				types.push("Customer");
			}
			if (!this.user.partners || !this.user.ownInstances || (!this.user.partners.length && !this.user.ownInstances.length)) {
				types.push("Contributor");
			}

			return types.join(', ');
		},
    },
    created() {
		if(!this.user){
			return this.$router.push('/');
		}

        this.userData = {
            isPartner: this.user.isPartner,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.email,
            phone: this.user.phone,
            company: this.user.company,
            industry: this.user.industry,
            userRole: this.user.userRole,
            address: this.user.address,
            country: this.user.country,
            region: this.user.region,
            city: this.user.city,
            postal: this.user.postal,
        };
    },
    methods: {
        getInitial,
        updateUser() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$api
                        .put(`/super-admin/users/${this.user.id}`, {
                            ...this.userData,
                            username: this.userData.email,
                        })
                        .then(() => {
                            this.loading = false;

                            this.$message.success(
                                "Successfully updated user details"
                            );
                            this.$router.push("/users");
                        })
                        .catch((err) => {
                            this.loading = false;
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, "Error while updating user details. Please try again!"));
							}
                        });
                } else {
                    console.error("Invalid form details");
                    return false;
                }
            });
        },

        resetPassword() {
            this.resetPasswordModal = false;
            this.$api
                .post(`/super-admin/users/${this.user.id}/reset-password`)
                .then(() => {
                    this.$message.success(
                        "Successfully send reset password link"
                    );
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while resetting password. Please try again!"));
					}
                });
        },

        validatePostalCodes(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter a Zip/Postal Code"));
            } else {
                if (!this.userData.country) {
                    callback(new Error("Please select a country first"));
                }
                const countryCode =
                    countryData.countries[this.userData.country];
                const result = postalCodes.validate(countryCode, value);
                if (result === true) {
                    callback();
                } else {
                    callback(new Error(result));
                }
            }
        },
    },
};
</script>

<style lang="scss">
.profile-card {
    text-align: center;
}
</style>
